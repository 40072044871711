import type { ChangeEventHandler, ReactNode } from "react";
import type { RegisterOptions } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { CheckboxItemProps, Option } from "../CheckboxGroup/CheckboxItem";
import { CheckboxItem } from "../CheckboxGroup/CheckboxItem";

interface CheckboxProps
  extends Omit<CheckboxItemProps, "option" | "checked" | "onChange"> {
  name: string;
  rules?: RegisterOptions;
  label?: ReactNode;
  description?: ReactNode;
  option?: Option;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const Checkbox = ({
  name,
  rules,
  label = "",
  description = "",
  ...rest
}: CheckboxProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <CheckboxItem
          onChange={e => onChange(e.target.checked)}
          checked={value}
          option={{ label, description }}
          {...rest}
        />
      )}
    />
  );
};

Checkbox.displayName = "Form.Checkbox";
